.ag-theme-quartz, .ag-theme-quartz-dark, .ag-theme-quartz-auto-dark {
    --ag-cell-horizontal-padding: 5px;
    --ag-header-background-color: #dddddd;
}

.ag-header-cell {
    white-space: pre-wrap;
}

.ag-cell {
    line-height: 30px;
}