.container {
  display: flex;
  flex-direction: column;
  background-color: white;  
  position: relative;
  margin-bottom: 0.1rem;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding-top: 10px;
}
.header-container{
  position: relative;
  margin-bottom: 0.1rem;
  color: black;
  justify-content: space-between;
}
.container-pie-chart {
  display: flex;
  flex-direction: column;
  background-color: white;  
  position: relative;
  margin-bottom: 0.1rem;
}
.container-line-chart {
  display: flex;
  flex-direction: column;
  background-color: white;  
  position: relative;
  margin-bottom: 0.1rem;
  border: 1px solid lightgray;
  padding: 1rem;

}
.container-group-chart{
  display: flex;
  flex-direction: column;
  background-color: white;  
  position: relative;
  margin-bottom: 0.1rem;
}
.react-tabs__tab-list{ 
  padding: 0px;
  border-bottom: 1px solid #ededed;
}

.tab-list-selected {
  background: #fff;
  border-color: #aaa;
  border: 2px solid transparent;
  border-radius: 8px 9px 2px 0;
}
.tab-list {
  display: inline-block;
  border-bottom: 2px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 14px;
}

.map-tab-list-selected {
  background: #fff;
  border-color: #aaa;
  border: 2px solid transparent;
  border-radius: 8px 9px 2px 0;
}

.map-tab-list {
  display: inline-block;
  border-bottom: 2px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 16px;
}
 
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-bottom: 2px solid transparent;
  background-color: #eeeeee;
  color: #909092;
  border-radius: 10px 10px 0 0;
  font-size: 12px;
  margin: 0 3px;

}

.react-tabs__tab--selected {
  background: #fff;
  padding: 5px 10px;
  font-weight: 550;
  border: 1px solid #dddddd;
  border-bottom: 2px solid white;
  border-radius: 10px 10px 0 0;
  margin: 0 3px;
}

.react-tabs__tab--disabled {
  color: grey;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
.absolute-position {
  position: absolute;
}

.header {
  color: black;
  padding: 0.25rem;
}

.expand-icon{
  transform: scale(1);
  right: 0;
  bottom: 0;
 }
 
 .expand-icon:hover{
   transform: scale(1.3);
   cursor: pointer;
 }
 .select{
  display: block;
    width: 6rem;
    height: 36px;
    background-color: white;
    border-radius: 4px;
    transition: 0.4s;
    margin-bottom: -15px;
 }

 .rs-button {
    min-width: 15rem;
    max-width: 15rem;
    padding: 8px 15px 10px 15px;
    color: black;
    border-radius: 8px;
    border: 1px solid lightgrey;
    transition: 0.4s; 
    height: 42px;
    margin: 1rem 0;
}

.rs-button-summit {
  background-color: white;
}