body{
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
}
.App {
  text-align: center;
  display: flex;
  width: 100%;
  min-height: 100vh;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  flex-grow: 1;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.chart:hover{
  transform: scale(1.5);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 4000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

:root {
  --ag-cell-horizontal-padding: 3px;
}
