

body{
  width: 100%;
  min-height: 100vh;
  margin-left: 0;
  margin-right: 0;
}
.modal-class{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: "400px";
    border: 1px solid #77af9c;
    background-color: white;
    border-radius: 6px;
    box-shadow: 24;
  }

.icon-style{
 transform: scale(1.3);
 position: absolute;
 right: -34px;
 bottom: 13px;
 justify-content: flex-end;
 color: black;
}

.printIcon{
  transform: scale(1.3);
  margin-left: 95%; 
}

.printIcon:hover{
  transform: scale(1.9);
  cursor: pointer;
}
.icon-style:hover{
  transform: scale(1.9);
  cursor: pointer;
}

  .flex-row{
    display: flex;
     flex-direction: row;
  }

  .chart:hover{
    transform: scale(1.5);
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 4000ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .nav-link{
    color: #159b1e;
  }


  .absolutePosition{
    color: gray;
    position: absolute;
  }
  
  .select{
    width: 6rem;
    margin-left: 75%;
}

.h6 {
  position: absolute;
  font-size: 1.12rem;
}



.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  margin : 0px 0px 0px 56rem;
  width: 20%;
}
  
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-Kontent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 65%; /* Could be more or less, depending on screen size */
  height: 31rem;
  border: 1px;
  border-radius: 6px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: 95%;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.gray-out {
  color: gray;
}