.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    margin : 0px 0px 0px 16rem;
    width: 20%;
  }
    
  .envSelect{
    width: 5rem;
  margin-left: 85%
  }
  .select{
    margin-left: 75%;
    border-radius: 6px;
  }