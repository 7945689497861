/* flex */
.flex-column {
  display: flex;
  flex-direction: column;
}

.grow-1 {
  flex: 1 1 0;
}

.grow-15 {
  flex: 1.5 1 0;
}

.grow-2 {
  flex: 2 1 0;
}

.flex-wrap {
  flex-flow: column wrap;
}

.flex-center {
  justify-content: center;
}

.flex-item-center {
  align-items: center;
}

.flex-self-center {
  align-self: center;
}

.flex-baseline {
  align-items: baseline;
}

.flex-stretch {
  flex: 1;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: end;
}
/* flex end */

.rs-w-title {
  font-size: 1.5rem;
  font-weight: 400;
}

/* dialog */
.rs-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.rs-d-container {
  max-width: 90%;
  max-height: 90%;    
  box-shadow: rgba(0, 0, 0, 0.25) 
      0px 12px 24px 0px;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
  overflow: auto;
}

.rs-widget-info {
  min-height: 15rem;
  min-width: 20rem;
  max-width: 25rem;
  font-weight: 400;
  border: 0px;
}

.widget-info {
  font-size: 1.2rem;
	vertical-align: super;
}

.widget-info-icon {
  transform: scale(1);
  vertical-align: baseline;
}

.widget-info-icon:hover{
 transform: scale(1.2);
 cursor: pointer;
}

.widget-info-body-container {
 min-height: 12rem;
 justify-content: space-between;
}

.widget-info-footer {
 align-self: end;
}

.textarea-widget-info {
  outline: none;
  resize: none;
  background: inherit;
  color: inherit;
  width: 100%;
  min-height: 7rem;
  overflow: visible;
  padding: 1rem;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.widget-modal-body{ 
  width: 50rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
 }

 .button-default {
  border-radius: 5px;
  min-width: 75px;
  cursor: pointer;
  display: inline-block;
}