.table-container{

}

.table-spacing{
    padding-top: 60px;
}

.grey-color{
  color: #8C8C8C ;
}

.row-flex{
    display: flex;
    flex-direction : row;
}

.select{
     width: 14rem;
     margin-left : 40rem;
}