* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  font-family: "Rubik", sans-serif;
  overflow: hidden auto;
  padding-right: 0;
  padding-left: 0;
  box-sizing:border-box;
  margin: 0;
  width: 100vw;
  max-width:100%;
  height: 100vh;
}
.loginInput {
    position: relative;
    margin: 25px;
  }
  
  
  .grey-text {
    color: #C6CACC;
  }
  .text-center {
    text-align: center;
  }

  .input-entry{
    text-align: center;
    margin-top: 2rem;
  }
  
  .width-fit-content {
    width: fit-content;
  }
  .line-height-small {
    line-height: 0rem;
}
  .buttonLogin{
    background-color: chocolate;
    color: white;
    display: block;
    width: 100%; 
  }
  
  .TextField{
    justify-content: flex-end;
  }
  .Box{
    box-shadow: 10px 5px 5px black;  }

 

  
  .back-logo{
      top: 4rem;
      width: 45rem;
      height: 45rem;
      color: rgba(0,0,0, 0.4);
  }
  .logo{
  width: 80px;
  height: 80px;
  }
  form{
      display: block;
      position: relative
  }
  
  /* form:after{
    display: block;
    position: relative;
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    z-index: 1;
    background-color: aquamarine;
  } */
  
  form .form-inner{     
      width: 100%;
      display: flex;
      height: fit-content;
    
  }
  
  input, button{
      appearance: none;
      background: none;
      border: none;
      outline: none;
  }
  
  form .form-inner h2{
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
  }
  
  form .form-inner .form-group{
      display: block;
      margin-bottom: 7px;
      text-align: center;
  }
  
  .form-inner .form-group:focus-within label{
      color: #554445;
  }
  
  .eye-button{
      position: absolute;
      left: 55px;
  }
  