.custom-card-container {
    min-width: 6.7rem;
    min-height: 2rem;
}
.main-container {
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 7px;
}
.landing-card-container {
    margin: auto 0.5rem;
    padding: 0.5rem;
    border: 2px solid lightgrey;
    border-radius: 7px;
}
.grid-title {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 0;
    flex-grow: 0;
}

h2.grid-title {
    color: black;
    font-size: 1.1rem;
    /* font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif; */
}

.pie-title {
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    flex-grow: 0;
}

div.pie-title {
    color: #666666;
    font-size: 1rem;
    font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.react-tabs__tab--selected>.pie-title {
    color: black;
}
.collapse-icon:hover {
    cursor: pointer;
  }
  .expand-icon-1:hover{
    cursor: pointer;
  }
.widget-container-data-grid {
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid #e6e6e6;
    background: #fff;
}
.flex-container {
    display: flex;
}
.tool-bar-item-container {
    display: flex;
}

.tool-bar-item {
    margin-right: 0.5rem;
    flex-grow: 1;
    width: 6rem;
    max-width:11rem;
}

.tool-bar-item-1 {
    flex-grow: 2;
    width: 20%;
}

.widget-container {
    display: flex;
}

.date-picker-input{
    max-width: 11rem;
}

.excess{
    background-color: #6eb0b1;
    border: solid 1px white;
    text-align: right;
    color: white;
}

.stockedOut {
    background-color: #de9081;
    border: solid 1px white;
    text-align: right;
    color: white;
}

.belowMin {
    background-color: #ced081;
    border: solid 1px white;
    text-align: right;
    color: white;
}

.normal {
    background-color: #6ed0a1;
    border: solid 1px white;
    text-align: right;
    color: white;
}

.belowEOP {
    background-color: #ceb081;
    border: solid 1px white;
    text-align: right;
    color: white;
}

.excessItem{
    background-color: #6eb0b1;
    border: solid 1px white;
    text-align: left;
    color: white;
}

.stockedOutItem {
    background-color: #de9081;
    border: solid 1px white;
    text-align: left;
    color: white;
}

.belowMinItem {
    background-color: #ced081;
    border: solid 1px white;
    text-align: left;
    color: white;
}

.normalItem {
    background-color: #6ed0a1;
    border: solid 1px white;
    text-align: left;
    color: white;
}

.belowEOPItem {
    background-color: #ceb081;
    border: solid 1px white;
    text-align: left;
    color: white;
}

.lowPercentage {
    background-color: #de9081;
    border: white 3px solid;
}

.mediumPercentage {
    background-color: #ced081;
    border: white 3px solid;
}

.highPercentage {
    background-color: #6eb0b1;
    border: white 3px solid;
}

.header-background {
    background-color: GhostWhite;
}

.expand-icon-2{
    transform: scale(1);
    margin-left: 0.75rem;
}

.expand-icon-2:hover{
    transform: scale(1.3);
    cursor: pointer;
}

/* dialog */
.rs-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.rs-d-container {
    max-width: 90%;
    max-height: 90%;    
    box-shadow: rgba(0, 0, 0, 0.25) 
        0px 12px 24px 0px;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: white;
    overflow: auto;
}

.widget-modal-body{ 
    width: 50rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
   }

.rs-widget-body {
    justify-content: space-around
}

.rs-widget {
    flex: 1;
    min-width: 23rem;
    min-height: 15rem;
    background: #fff;
}

.rs-w-b-container {
    min-height: 18rem;
    min-width: 30rem;
}


/* flex */
.flex-wrap {
    flex-flow: column wrap;
}

.flex-center {
    justify-content: center;
}

.flex-item-center {
    align-items: center;
}

.flex-self-center {
    align-self: center;
}

.flex-baseline {
    align-items: baseline;
}

.flex-stretch {
    flex: 1;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}


.flex-end {
    justify-content: end;
}

.footer {
    border-top: 1px solid #e0e0e0;
    padding: 2rem;
    margin-top: 1rem;
    transition: border-top 0.3s ease-in-out;
    line-height: 0.3rem;
    background: white;
}

.footer:hover {
    border-top: 1px solid #3498db;
}

.footer h6 {
    line-height: 0.7rem;
    font-weight: 500;
}