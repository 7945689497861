
.pfsa-logo {
    margin: 0 1em 0 0;
    float: left;
  }
  .logo-text{
    font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    width: 60%;
    float: left;
    text-align: left;
    color: white;
  }
  .logo-text-eng{
    margin: 0;
    font-size: 20px;
    color: #FFF;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .Nav-element{    
        display: flex;  
        margin-bottom: 1rem;  
        background-color: cadetblue;  
   }

   .cardSelected{
background: #F7F9FA;
border: 2px solid chocolate;
border-radius:16px ;
   }

   .cardStyle{
    background: #F7F9FA;
    border: 2px solid chocolate;
    border-radius:16px ;
       }

  .dashboard-grid::after {
  content: "";
  width: 18%;
  height: 120px;
}
  .wrapper{
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    grid-gap: 2rem;
    margin-block: 2rem;
    padding: 0 1rem;
    justify-items: center;
    align-items: center;

  }
  .lp_inputGrp_logo
{
      margin-top: 10px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
}
.lp_input_logo_img
{
    width: 3rem;
    height: 3rem;
    display: block;
    margin-right: auto;

}
.lp_input_logo_txt
{
    color:#008DC1;
    text-align: center;
    font-size: 22px;
    font-weight: bolder;
    padding-right: 25px;
}
.lp_inputGrp_txt
{
    margin-top: 5vh;
    position: relative;
    z-index: 2;
}
.nlp_input_logo_txt
{
  color: white;
  text-align: center;
  font-weight: 500;
}
.nlp_inputGrp_txt
{
  position: relative;
  z-index: 2;
}
.nlp_input_logo_img
{
  height: 12vh;
  display: block;
  margin-left: 47%;
  margin-right: auto;

}
.dashboard-card-icon {
    position: absolute;
    top: 10px;
    right: 25px;
}

.dashboard-icon {
  color: #ADAABF;
  font-size: 4.3rem;
}

.dashboard-card-title {
    position: absolute;
    width: 80%;
    left: 16px;
    bottom: 15.99px;
    font-weight: 500;
    font-size: 18px;
    color: #8C8C8C;
    line-height: 87.19%;
}
.card{
    position: relative;
    background: #F7F9FA;
    border: 1px solid #FF6D00;
    border-radius: 16px;
    cursor: pointer;
    
}
.cardText{
  /* transform: scale(1.03); */
  box-shadow: 0 5px 10px -5px rgba(30, 30, 110, 0.12), 0 8px 32px -8px hsla(175, 73%, 13%, 0.14), 0 -6px 32px -6px hsla(189, 75%, 45%, 0.02);
  cursor: pointer;
}

.cardText:hover{
  transform: scale(1.1);
  box-shadow: 0 13px 40px -5px rgba(198, 87, 7, 0.12), 0 8px 32px -8px hsla(257, 70%, 35%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  cursor: pointer;
}

.card-style{
  width: 1050px;
  margin: 2rem auto;
  flex-flow: wrap;
}

  .card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  } 



  .lpCards{
    transition-property: transform, box-shadow;
    transition-timing-function: cubic-bezier(0.2, 0, 0.2, 0.1);
  }
  .lpCards .md-title{
    font-weight: bolder;
  }
  .lpCards:hover:not(:active) {
    box-shadow: 0 0.5px 0.5px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
  }
  .dashboard-card {
    position: relative;
    background: #F7F9FA;
    border: 1px solid #FF6D00;
    border-radius: 16px;
    width:18%;
    height: 150px;
}
.dashboard-card.selected {
    background: #FE7610;
    border: 1px solid #FF6D00;
}
